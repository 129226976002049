import { useStaticQuery, graphql } from "gatsby"

const ALL_EPHARMACY_PRODUCTS = graphql`
  query MedicineSearchProducts {
    allProducts {
      nodes {
        brand
        molecule
        productTitle
        rxRequired
        rxLevel
        category
        classification
        itemCode
        price_list {
          price_list
          vatex_unit_price
          vat
        }
      }
    }
  }
`

export const useEpharmacyProducts = () => {
  const data = useStaticQuery(ALL_EPHARMACY_PRODUCTS)

  data.allProducts.nodes.filter(
    (node) =>
      node.productTitle !== "Abiraterone acetate 250mg Tablet (Zytiga 120s)"
  )

  return data
}
